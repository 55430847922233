// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-quiz-js": () => import("/Users/Tini/Documents/quizzelythree/src/templates/quiz.js" /* webpackChunkName: "component---src-templates-quiz-js" */),
  "component---src-pages-404-js": () => import("/Users/Tini/Documents/quizzelythree/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/Tini/Documents/quizzelythree/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quiz-button-js": () => import("/Users/Tini/Documents/quizzelythree/src/pages/QuizButton.js" /* webpackChunkName: "component---src-pages-quiz-button-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/Tini/Documents/quizzelythree/.cache/data.json")

